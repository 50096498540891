import LearnPathsComponent from '@components/web-landingpage/LearnPaths';
import Footer from '@components/web-landingpage/Footer';
import Benefits from '@components/web-landingpage/Benefits';
import DesktopVsMobile from '@components/web-landingpage/DesktopVsMobile';
import HeroComponent from '@components/web-landingpage/Hero';
import Navbar from '@components/web-landingpage/NavBar';
import SuccessStoriesComponent from '@components/web-landingpage/SuccessStories';
import StartLearningBanner from '@components/web-landingpage/StartLearningBanner';
import EditorsChoiceBannerComponent from '@components/web-landingpage/EditorsChoiceBanner';
import { Heading5 } from '@components/web-landingpage/Texts';
import SubscribeTestimonialComponent from '@components/web/subscribe/SubscribeTestimonial';

import { Toaster } from 'react-hot-toast';
import Script from 'next/script';
import { GetStaticProps } from 'next';
import { AuthAction, withUser } from 'next-firebase-auth';
import StoreAwardsComponent from '@components/web-landingpage/StoreAwards';
import FeaturedByCompaniesComponent from '@components/web-landingpage/FeaturedByCompanies';
import Head from 'next/head';

function WebLandingPage() {
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Mimo',
    url: 'https://mimo.org',
    logo: 'https://support.mimo.org/hc/theming_assets/01HZM5S83BW0QAQ6X8RRY8V5F5',
    contactPoint: {
      '@type': 'ContactPoint',
      email: 'press@mimo.org',
      contactType: 'Press Inquiries',
    },
    sameAs: [
      'https://twitter.com/getmimo',
      'https://www.facebook.com/mimo',
      'https://instagram.com/getmimo',
      'https://www.linkedin.com/company/mimo',
      'https://medium.com/getmimo',
    ],
    foundingDate: '2014',
    founders: [
      {
        '@type': 'Person',
        name: 'Dennis Daume',
      },
      {
        '@type': 'Person',
        name: 'Henry Ameseder',
      },
      {
        '@type': 'Person',
        name: 'Johannes Berger',
      },
      {
        '@type': 'Person',
        name: 'Lorenz Schimik',
      },
    ],
    description:
      'Mimo provides an engaging, interactive platform for learning coding and programming skills, empowering users to become creators in technology.',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Währinger Str. 2-4/48',
      addressLocality: 'Vienna',
      postalCode: '1090',
      addressCountry: 'Austria',
    },
    identifier: [
      {
        '@type': 'PropertyValue',
        propertyID: 'UID',
        value: 'ATU71685526',
      },
      {
        '@type': 'PropertyValue',
        propertyID: 'Company Registration',
        value: 'FN 459820 g',
      },
      {
        '@type': 'PropertyValue',
        propertyID: 'Court of Registry',
        value: 'Handelsgericht Wien',
      },
    ],
  };

  return (
    <>
      <Head>
        <title>
          Learn to code | Mimo: Python, JavaScript, HTML, CSS, and more
        </title>
      </Head>
      <div className="font-mimopro">
        <Script
          id="cookieyes"
          type="text/javascript"
          src="https://cdn-cookieyes.com/client_data/3ac4d695a7fb984394170f54/script.js"
          strategy="beforeInteractive"
        />
        <Script
          id="schemaMarkup"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
          strategy="beforeInteractive"
        />

        <Toaster
          toastOptions={{
            duration: Infinity,
          }}
          containerStyle={{ zIndex: 10000 }}
          position="top-center"
          reverseOrder={false}
        />
        <div className="m-0 flex w-full flex-col items-center justify-between  bg-marketing-background-primary">
          <Navbar></Navbar>
          <div className="mb-28 w-full max-w-[1364px] flex-1 tablet:mb-40">
            <section className="mb-16 mt-8 px-6 tablet:mt-8 tablet:px-10 tabletLg:mt-16">
              <HeroComponent />
            </section>
            <section className="mt-4 space-y-20 px-6 tablet:mt-10 tablet:px-10 desktop:px-10 ">
              <FeaturedByCompaniesComponent />
            </section>
            <section className="mt-4 space-y-20 tablet:mt-10  desktop:px-10">
              <div className="hidden px-8 tablet:block">
                <Heading5 className="mb-8 text-center">
                  Join 25+ million learners & start a career you&apos;ll love
                </Heading5>
                <SubscribeTestimonialComponent></SubscribeTestimonialComponent>
              </div>
            </section>
            <section className="mt-10 tablet:hidden">
              <EditorsChoiceBannerComponent />
            </section>
            <section className="mt-20 space-y-20 px-6 tablet:mt-40 tablet:px-10 tabletLg:mt-20 desktop:mt-40">
              <LearnPathsComponent />
            </section>
            <section className="mt-20 space-y-20 px-6 tablet:mt-40 tablet:px-10 tabletLg:mt-20 desktop:mt-40">
              <StoreAwardsComponent />
            </section>
            <section className="mt-20 space-y-6 px-6 tablet:mt-32 tablet:px-10">
              <DesktopVsMobile />
              <StartLearningBanner />
            </section>
            <section className="mt-20 px-6 tablet:mt-24 tablet:px-10">
              <Benefits />
            </section>
            <section className="mt-20 tablet:mt-40 tablet:px-10">
              <SuccessStoriesComponent />
            </section>
            <section className="mt-6 px-6 tablet:px-10"></section>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  };
};

export default withUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})(WebLandingPage);
